.main-body {
    height: 100%;
    display: table;
    width: 100%;
    text-align: center;
    color: white;
}

/* @media (orientation: landscape) and (max-width: 1024px) {
    .main-body {
        height: 150%;
    }
} */

.main-body .main-content{
    display: table-cell;
    vertical-align: middle;
}

.main-content .row{
    margin-bottom: 5rem;
}

.main-content .intro .brand-heading{
    font-size: 65px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .main-content .intro .brand-heading{
        font-size: 40px;
    }
}

.main-content .intro .intro-text{
    font-size: 1.25rem;
}