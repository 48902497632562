.footer {
    color: white;
    text-align: center;
    padding: 15px 0px 30px 0px;
    width: 100%;
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 300;
}

@media (max-width: 767px) {
    .NSA-breakdown {
        visibility: hidden;
        height: 0;
    }
}

.NSA-breakdown {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    text-align: left;
    font-size: calc(1.325rem + 0.9vw) !important;
    align-items: end;
}

p {
    line-height: 1.6;
}

.NSA-breakdown-image {
    width: 30px;
    margin-bottom: 10px;
}

.NSA-breakdown span {
    font-size: calc(1.275rem + 0.3vw) !important;
}

.footer-right p{
    font-size: 0.95rem;
}

.footer-address{
    padding-bottom: 10px;
    display: flex;
}

.footer-address-item {
    flex: 1 0 0%
}

@media  (max-width: 767px){
.footer-right p{
    font-size: small;
}
}
