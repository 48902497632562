#mainNav {
    text-transform: uppercase;
    border-bottom: 1px solid rgba(255, 255, 255, .3);
}

#mainNav a{
    color: white;
}

.navbar {
    background: rgba(0,0,0,0.57);
}

.navbar-brand-logo {
    width: 150px;
}

.navbar-nav {
    font-family: "Roboto", serif;
    font-size: 20px;
    text-align: center;
}